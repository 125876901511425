
import { defineComponent, reactive } from "vue";
import store from "@/store";
import DialogBase from "@/components/parts/DialogBase.vue";
import LoginButtons from "@/components/parts/LoginButtons.vue";
import {
  updatedThanksCount,
  clickedOpenButton,
  deleteArticle,
  doCopy,
  getThanksSubscribe,
  getFormatDate,
  getCleansingThanksCounts,
  getSns,
  compiledMarkdownText,
  clickedEditIcon,
  clickedDeleteIcon,
  clickedProfile,
  clickedTag,
} from "@/components/aboutArticleFunc";
import { getTargetThanksIcon } from "@/components/thanksIcons";
import { onBeforeRouteLeave, onBeforeRouteUpdate, useRouter } from "vue-router";
import constants from "@/components/constants";
import { closeModal } from "@/components/modalFunc";

export default defineComponent({
  components: {
    DialogBase,
    LoginButtons,
  },
  setup() {
    const router = useRouter();

    onBeforeRouteLeave(() => {
      if (state.thanksSubscribe !== undefined) {
        state.thanksSubscribe();
      }
    });

    onBeforeRouteUpdate(() => {
      if (state.thanksSubscribe !== undefined) {
        state.thanksSubscribe();
      }
    });

    const state = reactive({
      article: {} as { [key: string]: any },
      displayCounts: "0",
      cleansingCreatedAt: "",
      clickedThanksIcons: false,
      user: {} as { [key: string]: any },
      thanksIconPath: "",
      sns: [] as { [key: string]: string }[],
      loginModal: false,
      deleteModal: false,
      thanksSubscribe: undefined as any,
      isMyself: false,
      copied: false,
    });

    /// thanksアイコンをクリックした時に実行
    const clickedThanks = async () => {
      await updatedThanksCount(state);
    };

    /// 記事公開ボタンをクリックした時の実行
    const clickOpenButton = async () => {
      await clickedOpenButton(state);
    };

    /// 記事削除
    const deletedArticle = async () => {
      await deleteArticle(state.article.docId);
      router.push({
        path: "/",
      });
    };

    /// コピー処理
    const copy = (e: any) => {
      doCopy(e, state);
    };

    state.article = store.state.article;
    if (store.state.thanks[state.article.id] != undefined) {
      state.clickedThanksIcons = store.state.thanks[state.article.id]["added"];
    }

    state.thanksSubscribe = getThanksSubscribe(state);
    state.cleansingCreatedAt = getFormatDate(state.article.created_at.seconds);
    state.thanksIconPath = getTargetThanksIcon(state.article.thanks);
    state.displayCounts = getCleansingThanksCounts(state.article.thanks);

    state.user = store.state.profileUser;
    if (Object.getOwnPropertyNames(store.state.myUser).length != 0) {
      state.isMyself = store.state.myUser.users_id === state.user.users_id;
    }

    state.sns = getSns(false, state.user);

    return {
      constants,
      router,
      clickOpenButton,
      deletedArticle,
      copy,
      state,
      store,
      closeModal,
      clickedThanks,
      clickedOpenButton,
      deleteArticle,
      doCopy,
      compiledMarkdownText,
      clickedEditIcon,
      clickedDeleteIcon,
      clickedProfile,
      clickedTag
    };
  },
});
